
import * as React from "react"
import {useSpring} from 'react-spring'
import LinksLayout from "src/components/LinksLayout"
import {fadeInFromLeftConfig, fadeInFromRightConfig} from 'src/utils/animations'

import SpotifyImage from 'src/images/StreamingIcons/SpotifyFull.png'
import AmazonMusicImage from 'src/images/StreamingIcons/AmazonMusicFull.png'
import YoutubeMusicImage from 'src/images/StreamingIcons/YoutubeMusic.png'
import TidalImage from 'src/images/StreamingIcons/TidalFull.png'
import IGImage from 'src/images/StreamingIcons/IGFull.png'

const IndexPage = ({data}) => {
  const fadeInFromLeft = useSpring(fadeInFromLeftConfig)
  const fadeInFromRight = useSpring(fadeInFromRightConfig)

  const linkDataList = [
    {
      backgroundImageData: data.AfterTheRainImage.childImageSharp.fluid,
      link: 'https://music.apple.com/us/artist/prodbyaman/1540535028?itsct=music_box&amp;itscg=30200&amp;ct=artists_prod_by_aman&amp;app=music&amp;ls=1',
      fadeSpringClass: fadeInFromLeft,
      image: 'https://tools.applemediaservices.com/api/badges/listen-on-apple-music/mono-white/en-US?size=250x83&h=e584babc7e29e330d9eed6cb766b3c20',
      imageAlt: 'Listen on Apple'
    },
    {
      backgroundImageData: data.TheReclineImage.childImageSharp.fluid,
      link: 'https://open.spotify.com/artist/6PpgZNDpUyjEZUW90nfiJP?si=6XeNVlKXRzeQoyZGPWgTiw',
      fadeSpringClass: fadeInFromRight,
      image: SpotifyImage,
      imageAlt: 'Listen on Spotify'
    },
    {
      backgroundImageData: data.LoveThisMusicImage.childImageSharp.fluid,
      link: 'https://listen.tidal.com/artist/22423346',
      fadeSpringClass: fadeInFromLeft,
      image: TidalImage,
      imageAlt: 'Listen on Tidal',
    },
    {
      backgroundImageData: data.BadMoodImage.childImageSharp.fluid,
      link: 'https://music.youtube.com/channel/UChr5bNRy_oJe3Lbd89X1Zfg',
      fadeSpringClass: fadeInFromRight,
      image: YoutubeMusicImage,
      imageAlt: 'Listen on YouTube Music',
    },
    {
      backgroundImageData: data.CameFromTheColdImage.childImageSharp.fluid,
      link: 'https://music.amazon.com/artists/B08PDKDGMS/prodbyaman',
      fadeSpringClass: fadeInFromLeft,
      image: AmazonMusicImage,
      imageAlt: 'Listen on Amazon',
    },
    {
      backgroundImageData: data.FadeToBlackImage.childImageSharp.fluid,
      link: 'https://www.instagram.com/prodbyaman/',
      fadeSpringClass: fadeInFromRight,
      image: IGImage,
      imageAlt: 'Watch on IG',
    }
  ]

  return <LinksLayout linkDataList={linkDataList}/>
}

export default IndexPage

export const query = graphql`
  query {
    AfterTheRainImage: file(relativePath: { eq: "SongCovers/AfterTheRain.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    TheReclineImage: file(relativePath: { eq: "SongCovers/TheRecline.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    CameFromTheColdImage: file(relativePath: { eq: "SongCovers/CameFromTheCold.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    LoveThisMusicImage: file(relativePath: { eq: "SongCovers/LoveThisMusic.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    BadMoodImage: file(relativePath: { eq: "SongCovers/BadMood.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    FadeToBlackImage: file(relativePath: { eq: "SongCovers/FadeToBlack.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    },
  }
`